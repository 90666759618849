import React, { useState, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';

import Login from './pages/Login/Login';


const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const _handleLogin = (bool, claims) => {
    setLoggedIn(bool);
    console.log(claims);
  }
  
  return <Fragment>
    {loggedIn
      ? <div className="App"> Logged In </div>
      : <Login 
          handleLogin={_handleLogin}
        />
    }
  </Fragment>
}

export default App;
