import firebase from 'firebase/app';
import 'firebase/auth'


const config = {
  apiKey: "AIzaSyCnp9vk-am-qp4m3Yvvsl5xnG3nylNNW0o",
  authDomain: "auxwheatly.firebaseapp.com",
  projectId: "auxwheatly",
  messagingSenderId: "847224774835",
  appId: "1:847224774835:web:8d3427ab3d2e43c62790c6",
  measurementId: "G-29DWCEPE0R"
}
const auth = firebase.initializeApp(config, 'auth');

export default auth;
