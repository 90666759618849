import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import auth from '../../scripts/auth';
import Logo from './Logo.jpg';
import './Login.scss';

const LoginPage = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mess, setMess] = useState('');

  const _handleLogin = async e => {
    e.preventDefault();
    try {
      const user = await auth.auth().signInWithEmailAndPassword(username, password);
      console.log('Logged in with user:', user);
      const token = await auth.auth().currentUser.getIdTokenResult();
      const claims = token.claims || {};
      props.handleLogin(true, claims);
    } catch(err) {
      if (err.code === 'auth/network-request-failed') {
        setMess('Please check your internet connection.');
      } else {
        setMess('Login Attempt Failed');
      }
    }
  };

  const _handleChange = setFunction => event =>
    setFunction(event.target.value);

  return (
    <div className='Login' >
      <div className='Login-Container'>
        <div className='Login-Name'> A U X E T I C </div>
        <img className='Login-Logo' src={Logo} alt="Auxetic" />
        <div className='Login-Message'> {mess} </div>
        <form className='Login-Form' onSubmit={_handleLogin}>
          <TextField className='Login-Field' onChange={_handleChange(setUsername)} placeholder='USERNAME' fullWidth autoFocus />
          <TextField className='Login-Field' onChange={_handleChange(setPassword)} placeholder='PASSWORD' fullWidth type="password" />
          <Button className='Login-Button' variant="contained" fullWidth color="primary" type="submit"> Login </Button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
